<template>
  <div class="main">
    <div class="header">
      <div class="title">
        <img src="../assets/img/logo.png" style="width: 70px; height: 70px;">
        <div style = "display: flex;">
          <label style="font-size: 22px; cursor:pointer;" @click="toHome">首页</label>
          <div style="font-size: 22px; margin-left: 100px; color: blueviolet">关于我们</div>
        </div>
      </div>
      <img src="../assets/img/about.jpeg" style="width: 70%; margin-left: 50px; margin-top: 30px">
      <div style="margin-left: 50px; margin-top: 30px; font-size: 25px">公司简介</div>
      <div style="font-size: 18px; margin-left: 50px; margin-right: 50px; margin-top: 20px; width: 70%">欢迎来到【有招直聘】——一个引领未来招聘潮流的短视频招聘平台，我们专注于为餐饮、服装、美容美发店等服务业领域的企业提供高效、创新的人才招募解决方案。在这个快节奏的时代，我们深知传统招聘方式已难以满足行业对人才快速响应的需求，因此，【有招直聘】应运而生，旨在通过短视频这一生动、直观的形式，重新定义招聘体验。<br><br>
在【有招直聘】，企业可以发布富有创意的短视频岗位介绍，展示企业文化、工作环境及职位亮点，让求职者如同身临其境般感受未来工作场景，极大地提高了招聘信息的吸引力和转化率。无论是忙碌喧嚣的餐饮后厨、时尚潮流的服装秀场，还是技艺精湛的美容美发工作室，都能在这里找到属于它们的舞台，吸引志同道合的优秀人才加入。<br><br>
我们深知，人才是企业发展的核心动力。因此，【有招直聘】不仅为企业提供了一个展示自我、精准定位人才的平台，还通过智能算法匹配系统，根据求职者的兴趣偏好、技能特长，精准推送合适的岗位信息，实现供需双方的高效对接。这不仅节省了企业的招聘成本，也极大地缩短了求职者的求职周期，让每一次点击都充满可能。<br><br>
加入【有招直聘】，让我们一起打破传统招聘的界限，用创意和热情连接每一个梦想与机遇，共同推动服务业的繁荣发展。在这里，每一次的相遇都是一次美好的开始，让我们携手共创更加辉煌的明天！</div>
<div style="margin-left: 50px; margin-top: 30px; font-size: 25px">经营范围</div>
<div style="font-size: 18px; margin-left: 50px; margin-right: 50px; margin-top: 20px; width: 70%">人力资源服务（不含职业中介活动、劳务派遣服务），职业中介活动，信息技术咨询服务</div>
<div style="margin-left: 50px; margin-top: 30px; font-size: 25px">公司地址</div>
      <div style="font-size: 18px; margin-left: 50px; margin-right: 50px; margin-top: 20px; width: 70%">广州市天河区龙口中路194号101房自编4-17房</div>
      <div style="align-items: center; width: 100%; justify-content: center; display: flex; margin-top: 50px">
        <div style="font-size: 12px">Copyright © 2021 yikuai Inc. All Rights Reserved | </div>
        <a href="http://beian.miit.gov.cn/" style="font-size: 12px; margin-left: 5px">粤ICP备2021137428号</a>
      </div>
    </div>
  </div>
</template>

<script>
export default {
name: "About",
  methods:{
    toHome(){
      return this.$router.replace({path:'/home'});
    }
  }
}
</script>

<style scoped>
  .main {
    width: 100%;
  }

  .header {
    width: 100%;
    height: 100%;
  }

  .title {
    width: 100% - 200px;
    height: 80px;
    display: flex;
    justify-content: space-between;
    margin-left: 50px;
    margin-right: 100px;
    padding-top: 20px;
    flex-wrap: wrap;
    align-items: center;
  }
</style>
